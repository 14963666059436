import Container from '@gtg/components/container'
import React from 'react'
import styled from 'styled-components'
import Box from '@gtg/components/box'
import Footer from '../components/footer'
import GlobalWrapper from '../components/globalWrapper'
import Header from '../components/header'
import { color } from '../styles/theme'

const Section = styled.section`
  background: ${color.coal50};
`

const LegalNoticePage = () => {
  return (
    <GlobalWrapper>
      <Header />
      <Section>
        <Box pyMax="lg">
          <Container>
            <h1>Impressum</h1>
            <h2>Lenzbox GmbH</h2>
            <p>
              Postanschrift:
              <br />
              Mombacherstr. 68
              <br />
              55122 Mainz
              <br />
              Sitz: Philippsring 7, 55252 Mainz-Kastel
              <br />
              Eingetragen beim Amtsgericht Wiesbaden HRB 32213
            </p>
            <h2>Vertreten durch:</h2>
            <p>Dr. Cornelius Doniga</p>
            <h2>Kontakt</h2>
            <p>
              Tel.: +49 (0) 6131 6245703
              <br />
              Fax: +49 (0) 6131 6366734
              <br />
              Email: info[at]lenzbox.de
            </p>
            <h2>Haftungsausschluss für Inhalte</h2>
            <p>
              Die auf dieser Website enthaltenen Informationen wurden mit
              größter Sorgfalt erstellt und kontrolliert. Haftungsansprüche
              gegen “lenzbox”, die sich auf Schäden materieller oder ideeller
              Art beziehen, welche durch die Nutzung oder Nichtnutzung der
              eingestellten Informationen verursacht wurden, sind grundsätzlich
              ausgeschlossen. Wir behalten uns vor, Teile der Seiten oder das
              gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu
              ergänzen, zu löschen oder die Veröffentlichung zeitweise oder
              endgültig einzustellen.
            </p>
          </Container>
        </Box>
      </Section>
      <Footer />
    </GlobalWrapper>
  )
}

export default LegalNoticePage
